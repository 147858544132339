<template>
  <section class="pt-8">
    <h2 class="mt-0 border-b border-black relative w-auto inline-block text-3xl">
      БЕСТСЕЛЕРИ 
    </h2>
    <div class="mt-8">
      <Carousel :slides="props.products" :options="sliderOptions">
        <template #default="{ slide }">
          <Product :data="slide" :featured="true" class="mx-2 h-full"/>
        </template>
      </Carousel>
    </div>
  </section>
</template>

<script setup>

const props = defineProps({
  products: {
    type: Array,
    default: () => []
  }
})

const sliderOptions = ref({
  autoplay: {
    delay: 4000,
  },
  breakpoints: {
    300: {
      slidesPerView: 1
    },
    640: {
      slidesPerView: 2,
    },
    900: {
      slidesPerView: 3,
    },
  },
})

</script>
<style>
.swiper-slide {
  height: auto;
}
.custom-underline::before {
  content: "";
  --tw-bg-opacity: 1;
  background-color: rgba(35, 35, 35, var(--tw-bg-opacity));
  display: block;
  height: 0.25rem;
  position: absolute;
  left: 0px;
  bottom: -0.25rem;
  width: 100%;
}
</style>