<template>
  <div>
    <Carousel :slides="store.site.slides" :options="{
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      }
    }">
      <template #default="{ slide }">
        <img
          class="h-full w-full object-cover max-h-[600px]"
          :src="slide.url"
          :alt="slide.name"
          width="800"
          height="500"
        >
      </template>
    </Carousel>
    <div class="container px-6 mx-auto pb-10">
      <SectionFeaturedProducts :products="featuredProducts"/>
    </div>
    <div class="bg-white pb-12 -mb-12">
      <div class="container px-6 mx-auto">
        <SectionAboutUs />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from '@/stores/main'
import logo from '@/assets/logo.png'

const store = useStore()
const route = useRoute()

const featuredProducts = ref(store.products.filter((p) => p.featured))

useHead({
  title: `Profesional Cosmetics`,
  meta: [
    { name: 'description', content: `Магазин професійних засобів для здоров'я та краси твого волосся! Офіційний дистриб'ютор іспанського бренду PROFESIONAL COSMETICS в Україні` },
    { property: "og:type", content: "website" },
    { property: "og:url", content: `${store.site.domain}${route.fullPath}` },
    { property: "og:title", content: 'Profesional Cosmetics' },
    { property: "og:description", content: `Магазин професійних засобів для здоров'я та краси твого волосся! Офіційний дистриб'ютор іспанського бренду PROFESIONAL COSMETICS в Україні` },
    { property: "og:image", content: logo },
    { property: "twitter:card", content: "summary" },
    { property: "twitter:title", content: 'Profesional Cosmetics' },
    { property: "twitter:description", content: `Магазин професійних засобів для здоров'я та краси твого волосся! Офіційний дистриб'ютор іспанського бренду PROFESIONAL COSMETICS в Україні` },
    { property: "twitter:image", content: logo }
  ]
})

</script>
