<template>
  <section>
    <h1 class="text-[#f5d3d2] text-3xl text-center font-bold uppercase my-10">#WELOVEYOURHAIR</h1>
    
    <div class="lg:flex gap-6 lg:max-h-[760px]">
      <img 
        class="w-full object-cover img-position mx-auto mb-5 lg:mb-0 max-h-[500px] lg:max-h-full lg:w-2/4"
        src="https://firebasestorage.googleapis.com/v0/b/profesionalcosmetics-fca33.appspot.com/o/app%2Fabout-us.webp?alt=media&token=93518aa2-e701-497a-a96e-28e146ea62e1&_gl=1*1ikagx*_ga*NDIzODA4MjU3LjE2ODg3NTcwNjU.*_ga_CW55HF8NVT*MTY5ODkzNTIwNi4xMDkuMS4xNjk4OTM2MDk3LjQ5LjAuMA.."
        width="400"
        height="700"
        alt="Про нас"
      />
      <div class="bg-[#e2e2e4] p-6 flex flex-col">
        <h3 class="text-4xl font-bold mb-4">Profesional Cosmetics<br>в Україні</h3>
        <p class="text-base mb-4">...працюємо з 2019</p>
        <p class="text-base xl:text-lg mb-2">Ми - офіційні імпортери бренду "Profesional Cosmetics" в Україні. Бренд був зароджений у сонячній Барселоні ще у 1932 році, як сімейний бізнес та спеціалізувався на базових засобах догляду. Вже у 1982 році бренд розпочав свою професійну історію, коли виникли бажання та потреба у створенні засобів для майстрів-перукарів та салонів краси, що забезпечила б їхню якісну роботу. Унікальні формули засобів, досвід, та неймовірна любов до роботи відкрили шлях бренду "Profesional Cosmetics" у світ. На даний момент РС представлений більш, ніж у 50-ти країнах світу. У 2019 році бренд перше з'явився в Україні та вже встиг завоювати любов багатьох клієнтів у найрізноманітніших куточках нашої країни. Ми пропонуємо кожному клієнту високу якість продукції, що викличе любов до кожного мл нашого засобу, професіоналізм у роботі та індивідуальний підхід до кожного клієнта, адже ми любимо те, що робимо!</p>
        <NuxtLink
          class="border-2 border-black bg-[#f5d3d2] uppercase px-5 py-2 inline-block mt-auto w-fit"
          to="/about-us"
        >
          Про нас
        </NuxtLink>
      </div>
    </div>
  </section>
</template>

<script setup>

</script>
<style>
.img-position {
  object-position: 0% 30%;
}
</style>